.App {
    /* text-align: center; */
    /*background-color: #003060;*/
    background-color: #FFF;
    margin-left: auto;
    height: 100%;
    color: #003060;
}
body {
  background-color: #FFF;
  color: #003060;
}

.formControlLabel {
    color: #003060;
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

#sub-header {
  color: #003060;
  margin-bottom: 20px;
}

.editable {
  background-color: white;
}

.locked {
  background-color: #ededed;
}

.logo {
  height: auto;
}

textarea {
  background-color: #DDDDDD;
  color: #000;
}

#error-messages {
  background-color: white;
  width: 70%;
  font-size: 20px;
  color: red;
  text-align: center;
  margin: auto;
  margin-top: 10px;
  width: 50%;
  border-radius: 25px;
}

.form-seperator {
  background-color: #737373;
  padding: 10px 0px;
  font-weight: bold;
  color: white;
  margin: 10px 0px;
}

.form-section {
  margin: 20px 0px;
}

#checkbox-section {
  margin-top: 20px;
}

#debug-section {
  margin-top: 20px;
  font-size: 12px;
  border: 1px;
  border-color: black;
}

.text-field {
  background-color: #de5147;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.publisher {
    background-color: #FFF;
}

.App-header {
  /*background-color: #ededed;
  */align-items: center;
  display: flex;
  /* flex-direction: column; */
  font-size: calc(10px + 2vmin);
  justify-content: flex-start;
  min-height: 20vh;
  padding: 8px 60px;
  line-height: 0;
}
.main-container {
  min-height: calc(100vh - 337px);
}
.App-link {
  color: #61dafb;
}
.zones-section {
  margin: 0 auto;
}
.Mui-disabled .MuiInputBase-input {
  background: #ddd;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
