#search_box {
    background-color: #FFF;
    padding-bottom: 200px;
    padding: 32px 64px;
    box-sizing: border-box;
    color:#003060;
}
.search-box,
.error-msg,
.success-msg {
  min-height: calc(100vh - 337px);
  display: flex;
  align-items: center;
}
.error-msg,
.success-msg {
  justify-content: center;
  text-align: center;
  margin-top:40px;
  font-size: 18px;
}
.search-box .MuiInputBase-input {
  background: #fff;
}

/*global text boxes*/

.MuiTextField-root {
    background: white;
}