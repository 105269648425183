.Header {
	display: grid;
	grid-template-areas: "logo nav";

}

.Nav {
	grid-area: nav;
	display: grid;
	grid-template-columns: repeat(4, auto);
	align-items: center;
	justify-items: center;
}

..wrapper {
	display: "flex";
	flexDirection: "row";
	justifyContent: "center";
	alignItems: "center";
	margin: "0.5rem";
}

.partners_logo {
	height:100px;
	width:400px;
}

.govt_logo {
	height: 35px;
	width: 214px;
}

#logged-in {
	font-size:14px;
}

#logged-in a {
	color: #003060;
}